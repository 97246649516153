// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-14-tips-on-how-to-grow-afro-hair-js": () => import("./../../../src/pages/14-tips-on-how-to-grow-afro-hair.js" /* webpackChunkName: "component---src-pages-14-tips-on-how-to-grow-afro-hair-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-5-reasons-to-keep-your-hair-moisturised-js": () => import("./../../../src/pages/5-reasons-to-keep-your-hair-moisturised.js" /* webpackChunkName: "component---src-pages-5-reasons-to-keep-your-hair-moisturised-js" */),
  "component---src-pages-5-reasons-to-try-oil-natural-hair-js": () => import("./../../../src/pages/5-reasons-to-try-oil-natural-hair.js" /* webpackChunkName: "component---src-pages-5-reasons-to-try-oil-natural-hair-js" */),
  "component---src-pages-5-reasons-why-you-should-deep-condition-js": () => import("./../../../src/pages/5-reasons-why-you-should-deep-condition.js" /* webpackChunkName: "component---src-pages-5-reasons-why-you-should-deep-condition-js" */),
  "component---src-pages-7-benefits-of-protective-styling-js": () => import("./../../../src/pages/7-benefits-of-protective-styling.js" /* webpackChunkName: "component---src-pages-7-benefits-of-protective-styling-js" */),
  "component---src-pages-7-vitamins-for-healthy-hair-js": () => import("./../../../src/pages/7-vitamins-for-healthy-hair.js" /* webpackChunkName: "component---src-pages-7-vitamins-for-healthy-hair-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-afro-hair-products-js": () => import("./../../../src/pages/afro-hair-products.js" /* webpackChunkName: "component---src-pages-afro-hair-products-js" */),
  "component---src-pages-afro-hair-trends-2021-js": () => import("./../../../src/pages/afro-hair-trends-2021.js" /* webpackChunkName: "component---src-pages-afro-hair-trends-2021-js" */),
  "component---src-pages-afro-hair-tshirts-js": () => import("./../../../src/pages/afro-hair-tshirts.js" /* webpackChunkName: "component---src-pages-afro-hair-tshirts-js" */),
  "component---src-pages-apply-product-before-heat-js": () => import("./../../../src/pages/apply-product-before-heat.js" /* webpackChunkName: "component---src-pages-apply-product-before-heat-js" */),
  "component---src-pages-black-owned-hair-products-js": () => import("./../../../src/pages/black-owned-hair-products.js" /* webpackChunkName: "component---src-pages-black-owned-hair-products-js" */),
  "component---src-pages-cantu-review-js": () => import("./../../../src/pages/cantu-review.js" /* webpackChunkName: "component---src-pages-cantu-review-js" */),
  "component---src-pages-careful-combing-tips-natural-hair-js": () => import("./../../../src/pages/careful-combing-tips-natural-hair.js" /* webpackChunkName: "component---src-pages-careful-combing-tips-natural-hair-js" */),
  "component---src-pages-cut-off-split-ends-js": () => import("./../../../src/pages/cut-off-split-ends.js" /* webpackChunkName: "component---src-pages-cut-off-split-ends-js" */),
  "component---src-pages-going-homemade-with-natural-hair-products-js": () => import("./../../../src/pages/going-homemade-with-natural-hair-products.js" /* webpackChunkName: "component---src-pages-going-homemade-with-natural-hair-products-js" */),
  "component---src-pages-how-often-you-should-wash-natural-hair-js": () => import("./../../../src/pages/how-often-you-should-wash-natural-hair.js" /* webpackChunkName: "component---src-pages-how-often-you-should-wash-natural-hair-js" */),
  "component---src-pages-how-to-get-an-afro-js": () => import("./../../../src/pages/how-to-get-an-afro.js" /* webpackChunkName: "component---src-pages-how-to-get-an-afro-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-is-black-owned-racist-js": () => import("./../../../src/pages/is-black-owned-racist.js" /* webpackChunkName: "component---src-pages-is-black-owned-racist-js" */),
  "component---src-pages-look-after-your-edges-js": () => import("./../../../src/pages/look-after-your-edges.js" /* webpackChunkName: "component---src-pages-look-after-your-edges-js" */),
  "component---src-pages-natural-hair-care-glossary-js": () => import("./../../../src/pages/natural-hair-care-glossary.js" /* webpackChunkName: "component---src-pages-natural-hair-care-glossary-js" */),
  "component---src-pages-natural-hair-education-js": () => import("./../../../src/pages/natural-hair-education.js" /* webpackChunkName: "component---src-pages-natural-hair-education-js" */),
  "component---src-pages-natural-hair-with-manna-js": () => import("./../../../src/pages/natural-hair-with-manna.js" /* webpackChunkName: "component---src-pages-natural-hair-with-manna-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-shea-moisture-jamaican-castor-oil-conditioner-review-js": () => import("./../../../src/pages/shea-moisture-jamaican-castor-oil-conditioner-review.js" /* webpackChunkName: "component---src-pages-shea-moisture-jamaican-castor-oil-conditioner-review-js" */),
  "component---src-pages-shea-moisture-review-js": () => import("./../../../src/pages/shea-moisture-review.js" /* webpackChunkName: "component---src-pages-shea-moisture-review-js" */),
  "component---src-pages-silk-pillowcases-for-hair-care-js": () => import("./../../../src/pages/silk-pillowcases-for-hair-care.js" /* webpackChunkName: "component---src-pages-silk-pillowcases-for-hair-care-js" */),
  "component---src-pages-take-action-on-what-feels-right-js": () => import("./../../../src/pages/take-action-on-what-feels-right.js" /* webpackChunkName: "component---src-pages-take-action-on-what-feels-right-js" */),
  "component---src-pages-talking-natural-hair-with-celai-west-js": () => import("./../../../src/pages/talking-natural-hair-with-celai-west.js" /* webpackChunkName: "component---src-pages-talking-natural-hair-with-celai-west-js" */),
  "component---src-pages-talking-natural-hair-with-ebonie-roberge-js": () => import("./../../../src/pages/talking-natural-hair-with-ebonie-roberge.js" /* webpackChunkName: "component---src-pages-talking-natural-hair-with-ebonie-roberge-js" */),
  "component---src-pages-talking-natural-hair-with-elise-js": () => import("./../../../src/pages/talking-natural-hair-with-elise.js" /* webpackChunkName: "component---src-pages-talking-natural-hair-with-elise-js" */),
  "component---src-pages-talking-with-blessed-shaba-js": () => import("./../../../src/pages/talking-with-blessed-shaba.js" /* webpackChunkName: "component---src-pages-talking-with-blessed-shaba-js" */),
  "component---src-pages-talking-with-leah-alexxanderr-caine-js": () => import("./../../../src/pages/talking-with-leah-alexxanderr-caine.js" /* webpackChunkName: "component---src-pages-talking-with-leah-alexxanderr-caine-js" */),
  "component---src-pages-talking-with-sharna-js": () => import("./../../../src/pages/talking-with-sharna.js" /* webpackChunkName: "component---src-pages-talking-with-sharna-js" */),
  "component---src-pages-talking-with-tyler-js": () => import("./../../../src/pages/talking-with-tyler.js" /* webpackChunkName: "component---src-pages-talking-with-tyler-js" */),
  "component---src-pages-tips-on-box-braids-for-black-hair-js": () => import("./../../../src/pages/tips-on-box-braids-for-black-hair.js" /* webpackChunkName: "component---src-pages-tips-on-box-braids-for-black-hair-js" */),
  "component---src-pages-use-different-products-for-hair-and-scalp-js": () => import("./../../../src/pages/use-different-products-for-hair-and-scalp.js" /* webpackChunkName: "component---src-pages-use-different-products-for-hair-and-scalp-js" */),
  "component---src-pages-why-does-my-natural-hair-feel-dry-like-straw-js": () => import("./../../../src/pages/why-does-my-natural-hair-feel-dry-like-straw.js" /* webpackChunkName: "component---src-pages-why-does-my-natural-hair-feel-dry-like-straw-js" */),
  "component---src-pages-wigs-for-black-women-js": () => import("./../../../src/pages/wigs-for-black-women.js" /* webpackChunkName: "component---src-pages-wigs-for-black-women-js" */),
  "component---src-pages-wrap-up-your-natural-hair-js": () => import("./../../../src/pages/wrap-up-your-natural-hair.js" /* webpackChunkName: "component---src-pages-wrap-up-your-natural-hair-js" */)
}

